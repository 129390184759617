@tailwind base;
@tailwind components;
@tailwind utilities;


.p-card{
    background-color: #0F172A !important;
    transition: ease-out 0.4s;
    min-height: 200px !important;
}

.p-card:hover{
    background-color: #254499 !important;
}

small{
    text-align: justify !important;
    /* color: #F3F4F6 !important; */
}

#icon_list li {
    float: inline-end;
    list-style-type: none;
    vertical-align: middle;
    margin: 5px 10px;
}