:root{
    --body-color: #12121a;
    --back-color: #12121a;
    
}
body{
    background-color: var(--body-color) !important;
}
.pi-bars{
    font-size: 27px !important;
    font-weight: 400 !important;
    margin: 0px 17px !important;
}
.img-banner{
    background-image: url(../Images/1.jpg);
    
}
.overlay{
    position: absolute;
	top: 0;
	left: 0;
	background: #000;
	opacity: 0.5;
	height: 100%;
	width: 100%;
	
}
.name{
    font-size: 50px !important;
    font-weight: 700;
    background: linear-gradient(50deg,#458f8f,#ffffff);
	-webkit-background-clip: text !important;
	-webkit-text-fill-color: transparent;
}
#port{
    font-size: 40px !important;
    font-weight: 500;
    letter-spacing: 1px;
}


/* Customize Menubar in PrimeReact */
.p-menubar{
    border-radius: 0px !important;
    background-color: #12121a !important;
    border: 0px !important;
    color: white !important;
}


.p-menuitem-text, .p-menuitem-icon{
    color: white !important;
}
.p-submenu-list{
    background-color: #12121a !important;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover{
    background-color: #24262e !important;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link{
    background-color: #494a4e !important;
}
.p-menuitem:active{
    background-color:  #12121ab0 !important;
}

.p-menuitem-link:hover{
    background-color: #12121ab0 !important;
}

@media screen and (max-width: 960px)
{
    .p-menubar .p-menubar-root-list {
        background-color: #12121a !important;
    }
}
/* end of menubar */


/* Tabview Customize */

.p-tabview-panels,.p-tabview-nav,.p-tabview-nav-link{
    background-color: transparent !important;
    color: white !important;
}
.p-tabview-ink-bar{
    background-color: #484960 !important;
}


/* Card PrimeReact */


.p-card{
    background-color: #00000070 !important;
    color: white !important;
}

/* profile */
.opv{
    background: linear-gradient(#0000,#0000) !important;
    border-radius: 50%;
}
/* end of profile */


.text-parag{
    text-align: justify;
    line-height: 40px !important;
    text-indent: 50px;
    color: #c3c3c9bd !important;
    font-size: 18px !important;
}
small{
    color: #c3c3c9bd !important;
    /* color: rgba(108,117,125) !important; */
    letter-spacing: 1px;
    font-weight: 400;
}

/* social connect */
.list li{
    list-style: none;
    display: inline-block;
    margin: 0px 10px;
}
/* end of social */

/* Input tag Primereact Customize */

.p-inputtext{
    background-color: var(--back-color) !important;
    color: white !important;
}
.p-inputtextarea{
    resize: none !important;
}

/* Divider */
.p-divider-content{
    background-color: var(--back-color) !important; 
}
.interest{
    font-size: 15px !important;
}

/* Details */
.details li{
    list-style: disc;
    margin: 14px 0px;
}
.p-menubar{
    z-index: 10;
    display: flex !important;
    justify-content: space-between;
}
.p-menubar-button:hover,.p-menubar-button{
    background-color: transparent !important;
    border: none;
}
.p-menuitem-text{
    font-size: 14px !important;
}
ul li{
    list-style-type: none !important;
}